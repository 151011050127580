import { gql } from "@apollo/client";
import { TALENT } from "../fragments/Talent";
import { RACE } from "../fragments/Race";
import { WAGER_TYPE } from "../fragments/WagerType";

export const GET_GRAPH_TALENT_PICKS = gql`
  query getGraphTalentPicks(
    $wagerProfile: String
    $product: String
    $device: String
    $brand: String
    $trackCode: String
    $raceNumber: String
    $isRace: Boolean!
  ) {
    talentPicksList: talentPicks(
      filter: { isOpen: true }
      sort: { byPostTime: ASC }
      profile: $wagerProfile
    ) @skip(if: $isRace) {
      id
      ...Talent
      ...Race
      ...WagerType
      amount
      price
      selections {
        order
      }
      favorites {
        order
      }
    }

    talentPicksRace: talentPicks(
      trackCode: $trackCode
      raceNumber: $raceNumber
      sort: { byTalentPopularity: DESC }
      profile: $wagerProfile
    ) @include(if: $isRace) {
      id
      ...Talent
      ...Race
      ...WagerType
      amount
      price
      selections {
        order
      }
      favorites {
        order
      }
    }

    tvg1Races: races(
      profile: $wagerProfile
      sort: { byPostTime: ASC }
      filter: {
        onTvg: true
        isOpen: true
        rangeInMinutes: { beforeCurrentTime: 30 }
      }
      page: { current: 0, results: 1 }
    ) {
      id
      track {
        name
        code
      }
      number
    }
    tvg2Races: races(
      profile: $wagerProfile
      sort: { byPostTime: ASC }
      filter: {
        onTvg2: true
        isOpen: true
        rangeInMinutes: { beforeCurrentTime: 30 }
      }
      page: { current: 0, results: 1 }
    ) {
      id
      track {
        name
        code
      }
      number
    }
  }
  ${TALENT}
  ${WAGER_TYPE}
  ${RACE}
`;

export default GET_GRAPH_TALENT_PICKS;
